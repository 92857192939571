import React from 'react';
import './App.css'; // Assuming you will style using CSS
import { useTranslation } from 'react-i18next';

const Contact: React.FC = () => {

    const { t } = useTranslation();

    return (
        <section id="contact-section" className='app-contact'>
        <div className='app-contact-text'>
            <h2>{t('contact-us-header')}</h2>
            <p>Maree Global AG</p>
            <p>Grafenaustrasse 11</p>
            <p>6300 {t('contact-us-city')}</p>
            <p>{t('contact-us-country')}</p>
            <br></br>
            <div>
            <a href="mailto:hello@mareeglobal.com" title="Email us" className="contact-icon">
                <i className="fa fa-envelope"></i>
            </a>
            <a href="https://www.linkedin.com/company/maree-global-ag" target="_blank" rel="noopener noreferrer" title="Connect with us on LinkedIn" className="contact-icon">
                <i className="fab fa-linkedin"></i>
            </a>
        </div>
        </div>
    </section>
    );
}

export default Contact;
