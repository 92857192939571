import React, { useState } from 'react';
import './App.css'; // Ensure this contains the necessary styles
import logo from './MG_logo.png';
import { useTranslation } from 'react-i18next';

interface HeaderProps {
    homeRef: React.RefObject<HTMLDivElement>;
    servicesRef: React.RefObject<HTMLDivElement>;
    aboutRef: React.RefObject<HTMLDivElement>;
    contactRef: React.RefObject<HTMLDivElement>;
}

const Header: React.FC<HeaderProps> = ({ homeRef, servicesRef, aboutRef, contactRef }) => {
    const { i18n, t } = useTranslation();
    const [isMenuOpen, setIsMenuOpen] = useState(false); // State to handle menu toggle
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
        if (isMenuOpen) setDropdownOpen(false); // Close dropdown when closing menu
    };

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const handleLanguageChange = (lang: string) => {
        i18n.changeLanguage(lang);
        setDropdownOpen(false); // Close dropdown after selection
    };

    const getLanguageName = (code: string) => {
        switch (code) {
            case 'en': return 'English';
            case 'de': return 'Deutsch';
            case 'fr': return 'Français';
            default: return 'English'; // Default to English if unknown
        }
    };

    const scrollToSection = (ref: React.RefObject<HTMLDivElement>) => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
        setIsMenuOpen(false); // Close menu after navigation
    };

    return (
        <header className="app-header">
            <img src={logo} alt="Logo" className='app-header-logo' />
            <button className="menu-icon" onClick={toggleMenu}>
                &#9776; {/* Unicode for hamburger icon */}
            </button>
            <nav className={`app-header-nav ${isMenuOpen ? 'open' : ''}`}>
                <button onClick={() => scrollToSection(homeRef)} className="app-header-nav-item">{t('nav-button-home')}</button>
                <button onClick={() => scrollToSection(servicesRef)} className="app-header-nav-item">{t('nav-button-services')}</button>
                <button onClick={() => scrollToSection(aboutRef)} className="app-header-nav-item">{t('nav-button-about')}</button>
                <button onClick={() => scrollToSection(contactRef)} className="app-header-nav-item">{t('nav-button-contact')}</button>
                <div className="language-dropdown">
                    <button onClick={toggleDropdown} className="dropbtn">
                        {getLanguageName(i18n.language)} <i className="fa fa-caret-down"></i>
                    </button>
                    {dropdownOpen && (
                        <div className="dropdown-content">
                            <button onClick={() => handleLanguageChange('en')}>English</button>
                            <button onClick={() => handleLanguageChange('de')}>Deutsch</button>
                            <button onClick={() => handleLanguageChange('fr')}>Français</button>
                        </div>
                    )}
                </div>
            </nav>
        </header>
    );
}

export default Header;