import './App.css';  // Your custom CSS
import ServicesCarousel from './services-carousel';

import bespoke_solutions from './bespoke_solutions.png';
import crypto_price_feeds from './crypto-pricefeeds.png';
import crypto_smart_event_feed from './crypto-smarteventfeeds.png';
import crypto_smart_contract from './crypto-smartcontract.png';
import crypto_risk_management from './crypto-riskmanagement.png';
import { useTranslation } from 'react-i18next';



function Services()
{ 
    const { t } = useTranslation();

    const services = [
    
        {
            name: t('services-bespoke-title'),
            description: t('services-bespoke-description'),
            imageUrl:   bespoke_solutions
        },
        {
            name: t('services-cryptofeed-title'),
            description: t('services-cryptofeed-description'),
            imageUrl: crypto_price_feeds
        },
        {
            name: t('services-bi-title'),
            description: t('services-bi-description'),
            imageUrl:   crypto_smart_event_feed
        },
        {
            name: t('services-smd-title'),
            description: t('services-smd-description'),
            imageUrl:   crypto_smart_contract
        }
        ,
        {
            name: t('services-rm-title'),
            description: t('services-rm-description'),
            imageUrl:   crypto_risk_management
        }
        // Add more services as needed
    ];

    return (
    <div id="services-section" className="app-services">
        <div className='app-services-carousel'>
<ServicesCarousel services={services} />
    </div>
    </div>
    );
}

export default Services;