import React, { useEffect } from 'react';
import './App.css'; // Assuming you have styles defined in Header.css
import rust_image from './Rust_324_108.png';
import openai_image from './openai-white-lockup_392_108.png';
import solana_image from './solanaLogo_726_108.png';
import azure_image from './AzureLogo-388x108.png';
import ethereum_image from './ethereum-logo.png';
import bitcoin_image from './bitcoin-logo.png';
import bsc_image from './bsc-logo.png';
import { useTranslation } from 'react-i18next';


function Hero({ heroImage }: { heroImage: string }) {
    const { t } = useTranslation();

    useEffect(() => {
        document.documentElement.style.setProperty(
            '--hero-image-url',
            `url(${heroImage})`
        );
    }, [heroImage]);

    return (
        <div id="home-section" className="app-hero">
            <div className='app-hero-text'>
                <div className='app-hero-text-slogan '>{t('hero-text-slogan')}</div>
                <div className='app-hero-text-description'>{t('hero-text-description')}</div>
            </div>

            <div className='app-hero-logos'>
                {/* <img className='app-hero-logos-item' src={rust_image} /> */}
                <img className='app-hero-logos-item' src={openai_image} />
                <img className='app-hero-logos-item' src={azure_image} />
                <img className='app-hero-logos-item' src={bitcoin_image} />
                <img className='app-hero-logos-item' src={ethereum_image} />
                <img className='app-hero-logos-item' src={solana_image} />                               
                <img className='app-hero-logos-item' src={bsc_image} />
            </div>
        </div>
    );
}

export default Hero;