import React from 'react';
import Slider from 'react-slick';

// Define the interface for the services
interface Service {
  name: string;
  description: string;
  imageUrl: string;
}

interface ServicesCarouselProps {
  services: Service[];
}

const ServicesCarousel: React.FC<ServicesCarouselProps> = ({ services }) => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        swipe: true,  // Ensure swiping is enabled
        touchMove: true,  // Allows for dragging on desktop as well
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };
    
      return (
        <Slider {...settings}>
          {services.map((service, index) => (
            <div key={index} className="p-4">
              <img src={service.imageUrl} alt={service.name} className="w-full h-auto" />
              <h3 className="text-xl font-bold text-center mt-2">{service.name}</h3>
              <p className="text-gray-600 text-sm text-center">{service.description}</p>
            </div>
          ))}
        </Slider>
      );
};

export default ServicesCarousel;
