import React, { useRef } from 'react';
import './components/App.css';
import heroImage from './hero2.png';
import Header from './components/header';
import Hero from './components/hero';
import Services from './components/services';
import About from './components/about';
import Contact from './components/contact';
import './components/translation'


import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
function App() {
    const homeRef = useRef(null);  // Create a ref object
  const servicesRef = useRef(null);  // Create a ref object
  const aboutRef = useRef(null);  // Create a ref object
  const contactRef = useRef(null);  // Create a ref object

  return (
    <div className='app'>
        <Header homeRef={homeRef} servicesRef={servicesRef} aboutRef={aboutRef} contactRef={contactRef} />
        <div ref={homeRef} id="home-section">
        <Hero heroImage={heroImage} />
        </div>
      
        {/* Services section with ref attached */}
        <div ref={servicesRef} id="services-section">
            <Services />
        </div>
        <div ref={aboutRef} id="about-section">
        <About />
        </div>

        <div ref={contactRef} id="contact-section">
        <Contact />
        </div>
      
    </div>
);
}

export default App;