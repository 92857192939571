import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import about_video from './about.mp4';
import { useTranslation } from 'react-i18next';

const About = () => {
    const { t, i18n } = useTranslation();
    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        const videoElement = videoRef.current;
        if (!videoElement) return;

        // Create an IntersectionObserver with a broader range of visibility thresholds
        const observer = new IntersectionObserver(entries => {
            const entry = entries[0];
            // Trigger play when the video is at least 25% visible, instead of requiring more visibility
            if (entry.intersectionRatio >= 0.25) {
                videoElement.play().catch(error => {
                    console.error("Error playing video when partially visible:", error);
                });
            } else {
                videoElement.pause();
            }
        }, {
            threshold: [0, 0.25, 0.5, 0.75, 1] // Array of thresholds for multiple checks
        });

        observer.observe(videoElement);

        return () => {
            observer.disconnect();
        };
    }, [i18n.language]); // Dependency on language to handle video reset on language change

    return (
        <div id="about-section" className="app-about">
            <div className="app-about-video">
                 <video ref={videoRef} muted loop playsInline style={{ width: '100%', height: 'auto', objectFit: 'cover', pointerEvents: 'none' }}>
                    <source src={about_video} type="video/mp4" />
                    {t('about-video-error')}
                </video>
            </div>
            <div className="app-about-text">
                <h2>{t('about-header')}</h2>
                <p>{t('about-p1')}</p>
                <p>{t('about-p2')}</p>
                <p>{t('about-p3')}</p>
            </div>
        </div>
    );
};

export default About;
